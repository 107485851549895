<template>
  <div>
    <div v-show="viewMode==='grid'">
      <grid-toolbar
        :ref="gridToolbarName"
        :page-name="pageName"
        :grid-ref-name="gridName"
        :selected-rows-data="selectedRowsData"
        :title="title">
        <template slot="toolbarSlotBefore">
          <b-button-group
            v-if="gridConfig!==null && gridConfig.actionPageBtn!==undefined"
            size="sm"
          >
            <div
              v-for="(btn, index) in gridConfig.actionPageBtn"
              :key="index">
              <b-button
                v-if="(btn.permission === undefined || (btn.permission !== undefined && $can(btn.permission, 'penampunganAirHujan'))) && ((!trashedMode && btn.trashedModeOnly===undefined) || (trashedMode && (btn.trashedMode || btn.trashedModeOnly)))"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :title="btn.title"
                variant="primary"
                size="sm"
                style="margin-right: 2px"
                @click="actionPageClicked(btn.command)"
              >
                <feather-icon
                  :icon="btn.icon"
                  size="12"/>
              </b-button>
            </div>
          </b-button-group>
        </template>
        <template
          v-if="gridConfig!==null && gridConfig.actionMultipleRowBtn!==undefined"
          slot="selectedRowsCommandItem">
          <div
            v-for="(btn, index) in gridConfig.actionMultipleRowBtn"
            :key="index">
            <b-dropdown-item
              v-if="(btn.permission === undefined || (btn.permission !== undefined && $can(btn.permission, 'penampunganAirHujan'))) && ((!trashedMode && btn.trashedModeOnly===undefined) || (trashedMode && (btn.trashedMode || btn.trashedModeOnly)))"
              @click="selectedRowsCommand(btn.command)">
              <span class="text-uppercase">{{ btn.label }}</span>
            </b-dropdown-item>
          </div>
        </template>
      </grid-toolbar>

      <dx-data-grid
        :ref="gridName"
        :on-content-ready="gridContentReady"
        :focused-column-index.sync="focusedRowIndex"
        :on-key-down="gridKeyDown"
        :on-focused-row-changing="gridFocusedRowChanging"
        :on-focused-row-changed="gridFocusedRowChanged"
        :on-focused-cell-changed="gridFocusedCellChanged"
        :on-focused-cell-changing="gridFocusedCellChanging"
        :on-selection-changed="selectionChanged"
        :height="gridHeight">
        <div
          slot="actionTpl"
          slot-scope="data">
          <b-dropdown
            :ref="gridName+'RowAction'+data.data.data.id"
            variant="link"
            no-caret
            size="sm">
            <template
              #button-content
            >
              <feather-icon
                icon="MoreHorizontalIcon"
                size="16"
                class="align-middle text-body p-0"
              />
            </template>
            <div
              v-for="(btn, index) in gridConfig.actionRowBtn"
              :key="index">
              <b-dropdown-item
                v-if="(btn.permission === undefined || (btn.permission !== undefined && $can(btn.permission, 'penampunganAirHujan'))) && ((!trashedMode && btn.trashedModeOnly===undefined) || (trashedMode && (btn.trashedMode || btn.trashedModeOnly)))"
                class=""
                @click="actionRowClicked({mode:btn.command, rowData:data.data.data})">
                <feather-icon :icon="btn.icon"/>
                <span class="align-middle ml-50 text-uppercase">{{ btn.label }}</span>
              </b-dropdown-item>
            </div>
          </b-dropdown>
        </div>

        <dx-column-chooser mode="select"/>
      </dx-data-grid>
    </div>
    <div
      v-if="viewMode==='form'"
      class="mb-3">
      <form-app
        :ref="formName"
        :form-data-id="formDataId"
        :title="title"
        :form-name="formName"
        :on-form-close="formOnHide"
        :on-form-field-data-changed="onFormFieldDataChanged"
        :on-form-add-new="formOnAddNew"
        :on-form-before-save="formBeforeSave"
        :form-clone-mode="formCloneMode"
        :has-upload-files="true">

        <div slot="additionalForm">
          <dx-tab-panel
            ref="tabPanel"
            :items="[{title:'Foto', template: 'tab1'}]"
            :selected-index="0"
            :animation-enabled="true"
            :element-attr="{class:'mt-1 mb-1'}">
            <template #title="{ data: tabs }">
              <span>
                {{ tabs.title }}
              </span>
            </template>
            <template slot="tab1">
              <div class="containers">
                <!-- <div v-show="fileUploads.length === 0"> -->
                <dx-file-uploader
                  ref="fileUploader"
                  :on-value-changed="filesValueChanged"
                  :multiple="true"
                  :show-file-list="false"
                  select-button-text="Pilih File"
                  label-text=""
                  accept="image/*"
                  upload-mode="useForm"/>
                <!-- </div> -->

                <dx-tile-view
                  ref="tileView"
                  :items="fileUploads"
                  :height="206"
                  :base-item-height="50"
                  :base-item-width="500"
                  :item-margin="10"
                  direction="vertical"
                  no-data-text="">
                  <div
                    slot="item"
                    slot-scope="data">
                    <div class="price text-truncate">
                      {{ data.data.filename }}
                    </div>
                    <!-- <div
                      :id="`${formName}Foto-${data.id}`"
                      class="image"/> -->
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-danger"
                      size="sm"
                      @click.prevent.stop="filesDel(data.data)">
                      <feather-icon
                        icon="Trash2Icon"
                        class="mr-50" />
                    </b-button>
                  </div>
                </dx-tile-view>
              </div>
            </template>
          </dx-tab-panel>
        </div>
      </form-app>
    </div>
    <audit-grid
      v-if="viewMode==='audit'"
      :ref="auditGridName"
      :title="title"
      :name="auditGridName"
      :hide-event="formOnHide"/>
  </div>
</template>

<script>
import { DxTabPanel } from 'devextreme-vue/ui/tab-panel'
import { DxFileUploader } from 'devextreme-vue/ui/file-uploader'
import { DxTileView } from 'devextreme-vue/ui/tile-view'
import { DefaultPageData } from '@/modules/mixins/data'
import { nextTick } from '@vue/composition-api'

const _ = require('lodash')

export default {
  components: {
    DxTabPanel,
    DxFileUploader,
    DxTileView
  },
  props: {
    pageName: {
      type: String,
      default: 'PenampunganAirHujan'
    },
    title: {
      type: String,
      default: 'Penampungan Air Hujan'
    },
    gridHeight: {
      type: Number,
      default: function _default() {
        return window.innerHeight - 200
      }
    }
  },
  data() {
    const data = { ...DefaultPageData }
    this.$stateMerge(data, {
      viewMode: 'grid',
      gridName: `${this.pageName}Grid`,
      gridToolbarName: `${this.pageName}GridToolbar`,
      actionRowButtonName: `${this.pageName}GridActionRowBtn`,
      auditGridName: `${this.pageName}GridAudit`,
      formName: `${this.pageName}Form`,
      formOnHide: `${this.pageName}FormHideEvent`,
      formOnAddNew: `${this.pageName}FormAddNewEvent`,
      delRowsRoute: 'penampunganAirHujanDelBatch',
      restoreRowsRoute: 'penampunganAirHujanRestoreBatch',
      fileUploads: []
    })
    return data
  },
  created() {
    const vm = this
    vm.gridCreated()

    vm.$events.$on(`${vm.formName}FormMountedEvent`, () => {
      const formRef = vm.$refs[vm.formName]
      if (formRef === undefined) {
        return false
      }

      if (formRef.formDataEdit !== null) {
        formRef.form.getEditor('kota_id').option({
          dataSource: {
            store: vm.$aspnet.createStore({
              key: 'kota.id',
              loadUrl: 'api/data/kota',
              loadParams: {
                select: [
                  'kota.id',
                  'kota.nama'
                ],
                where: [['kota.id_provinsi', '=', formRef.formDataEdit.provinsi_id]]
              },
              onBeforeSend(operation, ajaxSettings) {
                if (operation === 'load') {
                  ajaxSettings.headers = {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                  }
                  ajaxSettings.data = {
                    data: JSON.stringify(ajaxSettings.data)
                  }
                }
              }
            }),
            paginate: true,
            pageSize: 10
          }
        })

        formRef.form.getEditor('kecamatan_id').option({
          dataSource: {
            store: vm.$aspnet.createStore({
              key: 'kecamatan.id',
              loadUrl: 'api/data/kecamatan',
              loadParams: {
                select: [
                  'kecamatan.id',
                  'kecamatan.nama'
                ],
                where: [['kecamatan.id_kota', '=', formRef.formDataEdit.kota_id]]
              },
              onBeforeSend(operation, ajaxSettings) {
                if (operation === 'load') {
                  ajaxSettings.headers = {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                  }
                  ajaxSettings.data = {
                    data: JSON.stringify(ajaxSettings.data)
                  }
                }
              }
            }),
            paginate: true,
            pageSize: 10
          }
        })

        formRef.form.getEditor('kelurahan_id').option({
          dataSource: {
            store: vm.$aspnet.createStore({
              key: 'kelurahan.id',
              loadUrl: 'api/data/kelurahan',
              loadParams: {
                select: [
                  'kelurahan.id',
                  'kelurahan.nama'
                ],
                where: [['kelurahan.id_kecamatan', '=', formRef.formDataEdit.kecamatan_id]]
              },
              onBeforeSend(operation, ajaxSettings) {
                if (operation === 'load') {
                  ajaxSettings.headers = {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                  }
                  ajaxSettings.data = {
                    data: JSON.stringify(ajaxSettings.data)
                  }
                }
              }
            }),
            paginate: true,
            pageSize: 10
          }
        })
      }
    })
  },
  mounted() {
    const vm = this
    vm.gridMount()
    vm.formMount()
  },
  methods: {
    bindMoustrap() {

    },
    actionPageClicked(command) {
      if (command === 'addNew') {
        this.viewMode = 'form'
      }
    },
    actionRowClicked(command) {
      const vm = this
      console.log('actionRowClicked', command)
      setTimeout(() => {
        if (command.mode === 'editRow' || command.mode === 'cloneRow') {
          vm.$refs[`${vm.gridName}RowAction${command.rowData.id}`].hide()
          // eslint-disable-next-line radix
          vm.formDataId = parseInt(command.rowData.id)
          vm.formCloneMode = command.mode === 'cloneRow'
          vm.viewMode = 'form'
        } else if (command.mode === 'deleteRow') {
          vm.delRows([command.rowData.id], false)
        } else if (command.mode === 'restoreRow') {
          vm.restoreRows([command.rowData.id], false)
        } else if (command.mode === 'auditRow') {
          vm.$refs[`${vm.gridName}RowAction${command.rowData.id}`].hide()
          const route = `api/penampunganAirHujan/${command.rowData.id}/audit`
          vm.viewMode = 'audit'
          nextTick().then(() => {
            vm.$refs[vm.auditGridName].updateRoute(route)
          })
        } else {
          vm.msgShow('Unauthorized access', 'error')
        }
      })
    },
    selectedRowsCommand(command) {
      if (command === 'deleteRow') {
        const vm = this
        setTimeout(() => {
          vm.delRows(this.$_map(this.selectedRowsData, 'id'))
          vm.$refs[vm.gridToolbarName].$refs[`${vm.gridName}SelectedRowsButton`].hide()
        }, 200)
      }
    },
    onFormFieldDataChanged(e) {
      const vm = this.$refs[this.formName]
      if (e.value === undefined || !vm.formItemsSetStatus) {
        return false
      }
      const { formDataEdit } = vm

      if (e.dataField === 'balai_id') {
        vm.form.getEditor('ws_id').option({
          value: formDataEdit !== null ? formDataEdit.ws_id : null,
          dataSource: {
            store: vm.$aspnet.createStore({
              key: 'master_ws.id',
              loadUrl: 'api/data/wilayahSungai',
              loadParams: {
                select: [
                  'master_ws.id',
                  'master_ws.nama'
                ],
                where: [['master_ws.balai_id', '=', e.value !== null ? e.value : 0]]
              },
              onBeforeSend(operation, ajaxSettings) {
                if (operation === 'load') {
                  ajaxSettings.headers = {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                  }
                  ajaxSettings.data = {
                    data: JSON.stringify(ajaxSettings.data)
                  }
                }
              }
            }),
            paginate: true,
            pageSize: 10,
            sort: ['master_ws.nama']
          }
        })
      }

      if (e.dataField === 'ws_id') {
        vm.form.getEditor('das_id').option({
          value: formDataEdit !== null ? formDataEdit.das_id : null,
          dataSource: {
            store: vm.$aspnet.createStore({
              key: 'master_das.id',
              loadUrl: 'api/data/daerahAliranSungai',
              loadParams: {
                select: [
                  'master_das.id',
                  'master_das.nama'
                ],
                where: [['master_das.ws_id', '=', e.value !== null ? e.value : 0]]
              },
              onBeforeSend(operation, ajaxSettings) {
                if (operation === 'load') {
                  ajaxSettings.headers = {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                  }
                  ajaxSettings.data = {
                    data: JSON.stringify(ajaxSettings.data)
                  }
                }
              }
            }),
            paginate: true,
            pageSize: 10,
            sort: ['master_das.nama']
          }
        })
      }

      // lokasi
      if (e.dataField === 'provinsi_id') {
        vm.form.getEditor('kota_id').option({
          value: formDataEdit !== null ? formDataEdit.kota_id : null,
          dataSource: {
            store: vm.$aspnet.createStore({
              key: 'kota.id',
              loadUrl: 'api/data/kota',
              loadParams: {
                select: [
                  'kota.id',
                  'kota.nama'
                ],
                where: [['kota.id_provinsi', '=', e.value !== null ? e.value : 0]]
              },
              onBeforeSend(operation, ajaxSettings) {
                if (operation === 'load') {
                  ajaxSettings.headers = {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                  }
                  ajaxSettings.data = {
                    data: JSON.stringify(ajaxSettings.data)
                  }
                }
              }
            }),
            paginate: true,
            pageSize: 10,
            sort: ['kota.nama']
          }
        })
      }

      if (e.dataField === 'kota_id') {
        vm.form.getEditor('kecamatan_id').option({
          value: formDataEdit !== null ? formDataEdit.kecamatan_id : null,
          dataSource: {
            store: vm.$aspnet.createStore({
              key: 'kecamatan.id',
              loadUrl: 'api/data/kecamatan',
              loadParams: {
                select: [
                  'kecamatan.id',
                  'kecamatan.nama'
                ],
                where: [['kecamatan.id_kota', '=', e.value !== null ? e.value : 0]]
              },
              onBeforeSend(operation, ajaxSettings) {
                if (operation === 'load') {
                  ajaxSettings.headers = {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                  }
                  ajaxSettings.data = {
                    data: JSON.stringify(ajaxSettings.data)
                  }
                }
              }
            }),
            paginate: true,
            pageSize: 10,
            sort: ['kecamatan.nama']
          }
        })
      }

      if (e.dataField === 'kecamatan_id') {
        vm.form.getEditor('kelurahan_id').option({
          value: formDataEdit !== null ? formDataEdit.kelurahan_id : null,
          dataSource: {
            store: vm.$aspnet.createStore({
              key: 'kelurahan.id',
              loadUrl: 'api/data/kelurahan',
              loadParams: {
                select: [
                  'kelurahan.id',
                  'kelurahan.nama'
                ],
                where: [['kelurahan.id_kecamatan', '=', e.value !== null ? e.value : 0]]
              },
              onBeforeSend(operation, ajaxSettings) {
                if (operation === 'load') {
                  ajaxSettings.headers = {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                  }
                  ajaxSettings.data = {
                    data: JSON.stringify(ajaxSettings.data)
                  }
                }
              }
            }),
            paginate: true,
            pageSize: 10,
            sort: ['kelurahan.nama']
          }
        })
      }

      // daerah layanan
      if (e.dataField === 'detail.manfaat_provinsi') {
        vm.form.getEditor('detail.manfaat_kota').option({
          value: formDataEdit !== null ? formDataEdit.detail.manfaat_kota.id : null,
          dataSource: {
            store: vm.$aspnet.createStore({
              key: 'kota.id',
              loadUrl: 'api/data/kota',
              loadParams: {
                select: [
                  'kota.id',
                  'kota.nama'
                ],
                where: [['kota.id_provinsi', '=', e.value.id !== null ? e.value.id : 0]]
              },
              onBeforeSend(operation, ajaxSettings) {
                if (operation === 'load') {
                  ajaxSettings.headers = {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                  }
                  ajaxSettings.data = {
                    data: JSON.stringify(ajaxSettings.data)
                  }
                }
              }
            }),
            paginate: true,
            pageSize: 10,
            sort: ['kota.nama']
          }
        })
      }

      if (e.dataField === 'detail.manfaat_kota') {
        vm.form.getEditor('detail.manfaat_kecamatan').option({
          value: formDataEdit !== null ? formDataEdit.detail.manfaat_kecamatan.id : null,
          dataSource: {
            store: vm.$aspnet.createStore({
              key: 'kecamatan.id',
              loadUrl: 'api/data/kecamatan',
              loadParams: {
                select: [
                  'kecamatan.id',
                  'kecamatan.nama'
                ],
                where: [['kecamatan.id_kota', '=', e.value.id !== null ? e.value.id : 0]]
              },
              onBeforeSend(operation, ajaxSettings) {
                if (operation === 'load') {
                  ajaxSettings.headers = {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                  }
                  ajaxSettings.data = {
                    data: JSON.stringify(ajaxSettings.data)
                  }
                }
              }
            }),
            paginate: true,
            pageSize: 10,
            sort: ['kecamatan.nama']
          }
        })
      }

      if (e.dataField === 'detail.manfaat_kecamatan') {
        vm.form.getEditor('detail.manfaat_kelurahan').option({
          value: formDataEdit !== null ? formDataEdit.detail.manfaat_kelurahan.id : null,
          dataSource: {
            store: vm.$aspnet.createStore({
              key: 'kelurahan.id',
              loadUrl: 'api/data/kelurahan',
              loadParams: {
                select: [
                  'kelurahan.id',
                  'kelurahan.nama'
                ],
                where: [['kelurahan.id_kecamatan', '=', e.value.id !== null ? e.value.id : 0]]
              },
              onBeforeSend(operation, ajaxSettings) {
                if (operation === 'load') {
                  ajaxSettings.headers = {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                  }
                  ajaxSettings.data = {
                    data: JSON.stringify(ajaxSettings.data)
                  }
                }
              }
            }),
            paginate: true,
            pageSize: 10,
            sort: ['kelurahan.nama']
          }
        })
      }
    },
    getProvinsi() {
      return (this.$refs[this.formName].formDataEdit !== null) ? [this.$refs[this.formName].formDataEdit.provinsi] : null
    },
    getKota() {
      return (this.$refs[this.formName].formDataEdit !== null) ? [this.$refs[this.formName].formDataEdit.kota] : null
    },
    getKecamatan() {
      return (this.$refs[this.formName].formDataEdit !== null) ? [this.$refs[this.formName].formDataEdit.kecamatan] : null
    },
    getKelurahan() {
      return (this.$refs[this.formName].formDataEdit !== null) ? [this.$refs[this.formName].formDataEdit.kelurahan] : null
    },
    getNamaCat() {
      return (this.$refs[this.formName].formDataEdit !== null) ? [this.$refs[this.formName].formDataEdit.nama_cat] : null
    },
    getBalai() {
      return (this.$refs[this.formName].formDataEdit !== null) ? [this.$refs[this.formName].formDataEdit.balai] : null
    },
    getWilayahSungai() {
      return (this.$refs[this.formName].formDataEdit !== null) ? [this.$refs[this.formName].formDataEdit.wilayah_sungai] : null
    },
    getDaerahAliranSungai() {
      return (this.$refs[this.formName].formDataEdit !== null) ? [this.$refs[this.formName].formDataEdit.daerah_aliran_sungai] : null
    },
    formMount() {
      const vm = this
      vm.$events.$on(`${vm.formName}FormMountedEvent`, () => {
        console.log(`${vm.formName}FormMountedEvent`)
        vm.fileUploads = []
        const { formDataEdit } = this.$refs[this.formName]
        if (formDataEdit !== undefined && formDataEdit !== null) {
          const realisasiDokumen = formDataEdit.realisasi_dokumen
          _.each(realisasiDokumen, (val, index) => {
            vm.fileUploads.push({
              id: index,
              file: null,
              filename: val.filename_ori
            })
          })
        }
      })
    },
    formBeforeSave() {
      const vm = this
      const { formData } = vm.$refs[vm.formName]

      return new Promise(done => {
        const fd = new FormData()

        _.each(formData, (val, key) => {
          if (key === 'detail') {
            fd.append(key, JSON.stringify(val))
          } else {
            fd.append(key, val)
          }
        })

        _.each(vm.fileUploads, (val, i) => {
          if (val.file !== null && val.file !== undefined) {
            if (val.file.constructor === File) {
              fd.append(`files[${ i }]`, val.file)
            }
          }
        })

        vm.$refs[vm.formName].formDataHasUploadFiles = fd
        done(true)
      }).catch(error => {
        this.msgShow(error, 'error')
        return false
      })
    },
    filesValueChanged(e) {
      const vm = this
      _.each(e.value, (val, index) => {
        vm.fileUploads.push({
          id: index,
          file: val,
          filename: val.name
        })
        console.log('vm.fileUploads', vm.fileUploads)
        // const reader = new FileReader()
        // reader.onload = ev => {
        //   // console.log(ev.target.result)
        //   // $(`#${vm.formName}Foto-${index}`).attr('style', `background-image : url(${e.target.result})`)
        // }
        // reader.readAsDataURL(val)
      })
      // vm.$refs.tabPanel.instance.repaint()
    },
    filesDel(data) {
      const vm = this
      vm.fileUploads = _.filter(vm.fileUploads, currentObject => currentObject.id !== data.id)
    }
  }
}
</script>

<style>
.dx-form-group-content {
  padding-top: 10px !important;
}
.dx-datagrid .dx-column-lines > td {
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
}
.dx-row-lines > td {
  background-color: rgba(191, 191, 191, 0.15);
}
</style>
